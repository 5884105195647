$white: #fff;
$darkWhite: #f7f7f7;
$grey: #e1e1e1;
$greyDarker: #ececec;
$darkGrey: #5b5b5b;
$black: #000000;

$red: #f34933;
$yellow: #ffe15f;
$orange: #f3c352;
$darkOrange: #dea41c;
$lightGreen: #79b672;
$green: #5ab379;
$flashGreen: #99c455;
$darkGreen: #368735;
$darkerGreen: #2c6b2b;
$greenBlue: #43a59d;
$lightBlue: #5fafc8;
$blue: #0096b6;
$darkBlue: #027b82;
$darkBlue2: #036f86;
$darkBlue3: #0c4754;
$darkBlue4: #14353c;

@import "~slick-carousel/slick/slick";
@import "~sidr/dist/stylesheets/jquery.sidr.dark.min.css";
@import "~pretty-checkbox/src/pretty-checkbox";
@import "jquery.slider";
@import "jtoggler.styles.css";
@import "lightbox.css";
@import "generic/reset";
@import "generic/mediaqueries";
@import "settings/colors";
@import "settings/fonts";
@import "utils/bootstrap";
@import "utils/commons";
@import "utils/inputFile";
@import "utils/selectric";
@import "utils/footer";

#cookie-compliance {
	display: none !important;
}

#paramCookie, #cookie-compliance {
	display: none;
}

.divPagination {
	.pagination {
		display: flex;
		justify-content: center;

		li {
			padding: 0 4px;
		}
	}
}

body {
	min-width: 320px;

	#adventure {
		width: 190px;
		margin-top: -13px;
		margin-right: 12px;
	}

	.recaptcha-policy {
		display:none;
	}

	&.front {
		header {
			background-position: center bottom;

			h2 {
				text-shadow: 2px 2px 15px black;

			}

		}

		header .headerContent {
			text-align: center;
		}
	}

	.container-style {
		padding: 30px;

		p, h2, h3, h4 {
			color: black;
		}
	}

	.homeContent {

		.sejours {
			background-image: url('../images/home-sejours.jpg');
			background-repeat: no-repeat;
			background-size: cover;

			order: 1;

			@include phone-down {
				& .half-width__wrapper {
					margin: 40px 0;
					padding: 0 10%;
				}
			}

			@include desktop-up {
				order: 0;
			}

			.afterList {
				margin-top: 20px;
				text-align: right;
			}

			.homeAccrocheContainer {
				margin-bottom: 20px;

				* {
					color: $black;
				}
			}

		}

		.saison {
			align-items: stretch;
			order: 0;

			@include tablet-big-down {
				order: 2;
			}

			&__news {
				background-color: $blue;
				display: flex;
				flex-flow: row wrap;
				min-height: 50%;

				width: 100%;

				@include tablet-small-down {
					height: auto;
					min-height: auto;
				}

				&__illustration {

					background-image: url('../images/home-valise.jpg');
					background-repeat: no-repeat;
					background-size: cover;
					max-height: 100%;
					background-position: center;

					@include desktop-down {
						width: 100%;
						min-height: 250px;
					}

					@include tablet-small-down {
						width: 50%;
						min-height: 300px;
					}

					@include phone-down {
						display: none;
					}
				}

				&__opening {

					@include desktop-down {
						width: 100%;
						min-height: 250px;
					}

					@include tablet-small-down {
						width: 50%;
					}

					@include phone-down {
						width: 100%;
					}


					.half-width__wrapper {
						margin: 20px 40px;
					}
				}

			}

			&__carte {
				background-color: $yellow;
				width: 100%;
				height: 50%;
				min-height: 50%;

				display: flex;
				flex-flow: column nowrap;
				align-items: stretch;

				@include tablet-small-down {
					height: auto;
					min-height: auto;
				}

				.homeAccrocheContainer {
					height: 50px;

					* {
						color: $black;
					}
				}

				.half-width__wrapper {
					margin: 20px 80px;

					@include phone-down {
						margin: 20px 0;
						padding: 0 10%;
					}

					@include tablet-landscape-only {
						margin: 20px 0;
						padding: 0 10%;
					}

					@include tablet-small-down {
						margin-left: 0;
						margin-right: 0;
						padding-left: 80px;
						padding-right: 80px;
					}

				}

				&__container {
					height: calc(100% - 50px);
					width: 100%;
					min-height: 350px;
				}

				#map {
					width: 100%;
					height: 100%;
				}

			}
		}

		.europe {
			background-color: $greenBlue;
			background-image: url('../images/home-europe.png');
			background-size: 50%;
			background-repeat: no-repeat;
			background-position: 130% 50%;

			order: 4;

			@include phone-down {
				background-size: 70%;
				background-position: 270% 50%;
			}
			@include tablet-portrait-only {
				background-size: 30%;
				background-position: 90% 50%;
			}
			@include tablet-small-only {
				background-size: 30%;
				background-position: 90% 50%;
			}

			@include tablet-landscape-only {
				.homeAccrocheContainer {
					width: 60%;
				}
			}

			@include desktop-up {
				order: unset;
			}
		}

		.animateur {
			background-image: url('../images/home-animateur.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: 100% 50%;

			order: 3;

			@include desktop-up {
				order: unset;
				background-position: 100% 50%;
			}
		}

		.valeurs {
			height: 470px;
			background-image: url('../images/home-valeurs.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;

			order: 4;

			@include desktop-up {
				order: unset;
			}

			h2 {
				font-size: $xx-large;
				line-height: 1.2em;
				margin-bottom: 1em;
			}

			.half-width {
				align-items: flex-start;
				margin-top: 30px;
			}
		}
	}

	&.horizon {
		header {
			background-image: url('../images/header-horizon.jpg');
			background-position: center center;
		}

		.horizon {
			background-color: #4dab8f;
		}

		.horizonIllustration {
			background-image: url('../images/horizon-intro.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;

		}

		.horizon2 {
			align-items: flex-start;
		}

		.horizon2bis {
			align-items: flex-start;

			.buttonContainer {
				text-align: center;
				margin-top: 40px;
			}
		}
	}

	&.anniversaire {
		header {
			background-image: url('../images/banner.jpg');
			background-position: center center;
			min-height: 900px;

			@include tablet-big-down {
				min-height: 620px;
			}

			@include tablet-small-down {
				min-height: 560px;
				background-image: url('../images/bannerMobile.jpg');
			}

			.menuContent .menuLogo {

				@include tablet-small-down {
					top:-100px;
				}
			}
		}

		.horizon {
			background-color: #007AC1;
			align-items: inherit;

			.half-width__wrapper {
				width:auto;
				text-align: center;

				img{
					max-width:150px;
					margin-bottom: 30px;
				}

			}

			.buttonContainer {
				margin-top: 30px;
			}

		}
		.horizon .horizonBas {
			background-color: #005F97;
			width: 100%;
			text-align: center;

			* {
				color:$white;
			}

			img{
				max-width:420px;
			}

			.sep {
				height:1px;
				background: #ffffff;
				border:none;
				margin-bottom: 60px;
				padding: 0;
			}
		}

		.horizonIllustration {
			background-image: url('../images/horizon-intro.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;

		}

		.horizon2 {
			align-items: flex-start;
			background: #FBC317;

			text-align: center;

			img{
				max-width:150px;
				margin-bottom: 30px;
			}

			.buttonContainer {
				margin-top: 30px;
			}


		}

		.horizon2bis {
			align-items: flex-start;

			.buttonContainer {
				text-align: center;
				margin-top: 40px;
			}
		}
	}

	&.contact {
		header {
			background-image: url('../images/header-contact.jpg');
			background-position: center center;

		}

		.contactMap {
			&__container {
				min-height: 300px;
				width: 100%;
				height: 100%;

				#contactMap {
					height: 100%;
					width: 100%;
				}

				.mapWindow {
					padding: 20px;
					h3, p, a {
						color: $darkBlue4;
					}
				}
			}

		}

		.contactForm {
			background-color: $darkWhite;

			h2 {
				color: $blue;
			}

			form {
				> div {
					width: 100%;


					&#nom, &#prenom {
						width: calc(50% - 10px);
					}

					&#adresse {
						width: calc(80% - 10px);
					}

					&#codePostal {
						width: calc(20% - 10px);

					}

					&#localite, &#telephone, &#email {
						width: calc(33% - 10px);
					}

					@include phone-down {
						width: 100% !important;

						&#codePostal {
							width: calc(30% - 10px) !important;
						}

						&#localite {
							width: calc(70% - 10px) !important;;
						}
					}

					@include tablet-landscape-only {
						width: 100% !important;

						&#nom, &#prenom {
							width: calc(50% - 10px) !important;
						}

						&#codePostal {
							width: calc(30% - 10px) !important;
						}

						&#localite {
							width: calc(70% - 10px) !important;;
						}

						&#telephone, &#email {
							width: calc(50% - 10px) !important;
						}
					}
					@include tablet-small-only {
						width: 100% !important;

						&#nom, &#prenom {
							width: calc(50% - 10px) !important;
						}

						&#codePostal {
							width: calc(30% - 10px) !important;
						}

						&#localite {
							width: calc(70% - 10px) !important;;
						}

						&#telephone, &#email {
							width: calc(50% - 10px) !important;
						}
					}
				}
			}

		}

	}

	&.contenu {
		header {
			background-image: url('../images/header-contenu.jpg');
			background-position: center top;

		}

		.contenuTitle {
			h2, h3 {
				color: $blue;
			}
		}

		.content {
			align-items: flex-start;
		}
	}

	&.animateurs {
		header {
			background-image: url('../images/header-animateurs.jpg');
			background-position: top center;
		}

		.animateursIntro {
			background-color: $greenBlue;
		}

		.animateursIllustration {
			background-image: url('../images/animateurs-intro.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;
		}

		.presentation {
			&__item {
				display: flex;
				flex-flow: column wrap;
				justify-content: flex-start;

				width: calc(33% - 40px);
				background-color: $darkWhite;
				padding: 20px 30px;
				border-bottom: 5px solid black;


				@include phone-down {
					width: 100%;
					margin-bottom: 20px;
				}

				@include tablet-portrait-only {
					width: calc(50% - 10px);
					margin-bottom: 20px;
				}
				@include tablet-small-only {
					width: calc(50% - 10px);
					margin-bottom: 20px;
				}

				@include tablet-landscape-only {
					width: calc(33% - 10px);
					margin-bottom: 20px;
				}

				&s {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
				}

				.wysiwyg * {
					color: $darkBlue3;
				}

				&__title {
					margin: 20px 0;

					* {
						color: $darkBlue3;
					}

					img {
						height: 40px;
					}

					h3 {
						margin-top: 10px;
						font-weight: 600;
						text-align: center;
					}

					svg {
						margin: auto;
						display: block;
					}
				}

				&:nth-child(1) {
					border-bottom-color: $darkBlue;

					h3 {
						color: $darkBlue;
					}

					svg {
						fill: $darkBlue;
					}
				}

				&:nth-child(2) {
					border-bottom-color: $blue;

					h3 {
						color: $blue;
					}

					svg {
						fill: $blue;
					}
				}

				&:nth-child(3) {
					border-bottom-color: $lightGreen;

					h3 {
						color: $lightGreen;
					}

					svg {
						fill: $lightGreen;
					}
				}
			}
		}

		.inscription {
			background-image: url('../images/animateurs-inscription.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center center;

			align-items: flex-start;

			&__container {
				width: 300px;
				height: 300px;
				display: flex;
				flex-flow: column wrap;
				justify-content: center;

				a:hover strong {
					text-decoration: underline;
				}
			}

			.block1 {
				justify-content: flex-end;

				@include tablet-small-down {
					justify-content: center;
				}

				.inscription__container {
					align-items: center;
					margin-top: 60px;
					text-align: center;
					background-color: $darkBlue3;

					@include tablet-portrait-only {
						margin-right: 125px;
					}
					@include tablet-small-only {
						margin-right: 125px;
					}

					img {
						width: 90px;
					}

					h3 {
						font-weight: 600;
						margin: 30px;
					}

				}
			}

			.block2 {

				@include tablet-small-down {
					justify-content: center;
				}

				.inscription__container {
					margin-top: 200px;
					margin-bottom: 60px;
					padding-left: 40px;
					background-color: $white;

					@include phone-down {
						margin-top: 0;
					}

					@include tablet-portrait-only {
						margin-top: 0;
						margin-left: 125px;
					}
					@include tablet-small-only {
						margin-top: 0;
						margin-left: 125px;

					}

					h3 {
						font-weight: 600;
						color: $darkBlue;
					}

					p, a {
						color: $darkBlue3;
						text-decoration: none;
					}

				}
			}
		}
	}

	&.sejours {

		header {
			background-image: url('../images/header-sejours.jpg');
			background-position: center center;

			@include desktop-big-down {
				min-height: 600px;
			}

			.headerContent {
				position: absolute;
				background-color: rgba(0, 0, 0, 0.7);
				bottom: 0;
				text-align: center;

				@include phone-down {
					position: relative;
				}

				@include tablet-portrait-only {
					position: relative;
					margin-top: 50px;
				}
				@include tablet-small-only {
					position: relative;
					margin-top: 50px;
				}

				@include tablet-landscape-only {
					position: relative;
					margin-top: 100px;
				}

				.full-width__wrapper {
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;

					.half-width {
						min-height: 0px;
						width: calc(50% - 20px);

						@include tablet-small-down {
							width: 100%;

						}

						&.left {
							text-align: left;

							@include phone-down {
								display: none;
							}

							@include tablet-portrait-only {
								display: none;
							}

							h2 {
								font-size: $large;
								margin-bottom: 0;
								color: $orange;
							}

							h3 {
								font-size: $small;
								margin-bottom: 0;
								color: $orange;
								margin-bottom: 20px;
							}
						}

						&.right {
							display: flex;
							flex-flow: column wrap;
						}
					}
				}


			}
		}

		.mapContainer {
			height: 500px;

			#sejoursMap {
				width: 100%;
				height: 100%;
			}
		}

		.filters {
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-end;
			align-items: flex-end;
			margin-bottom:20px;

			@include phone-down {
				flex-direction: column;
				align-items: flex-start;
			}

			h3 {
				text-transform: uppercase;
				font-size: $small;
				font-weight: 600;
				vertical-align: baseline;
			}

			form {

				display: flex;
				flex-flow: row wrap;
				justify-content: flex-end;


				> div {
					width: auto;
					min-width: 90px;
					margin-left: 20px;


					&.typeStaysFilters {
						min-width:180px;
					}

					&.btnFormFilter {
						min-width:0;

						button {
							background-color: #007a82;
							color: #fff;
							border: none;
							padding: 13px 20px;
							margin-top: 15px;
						}
					}

					@include phone-down {
						margin-left: 0;
						margin-top: 20px;
						width: auto;
					}

					label {
						display: block;
						text-transform: uppercase;
						font-weight: 400;
						font-size: $x-small;

						@include phone-down {
							display: inline;
						}
					}

					select {
						min-width: 150px;
						width: 100%;

						@include phone-down {
							margin-left: 20px;
							width: auto;
						}
					}
				}
			}

		}

		.sejours__list {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			.sejours__list__item {
				margin-top: 40px;
				width: calc(50% - 20px);

				@include tablet-small-down {
					width: 100%;
				}
			}
		}

		.divPagination {
			margin-top: 60px;

			ul {
				display: flex;
				flex-flow: row wrap;
				justify-content: center;

				li {
					a, span {
						margin: 0 4px;
						display: inline-block;
						text-align: center;
						padding: 10px;
						font-weight: 400;
						background-color: $darkWhite;
						text-decoration: none;
						border-radius: 30px;
						min-width: 38px;

						&.selected {
							background-color: $white;
						}
					}

					a:hover {
						background-color: $orange;
					}

					&.disabled {
						opacity: .2;
					}

					&.active span {
						background-color: $orange;
					}
				}
			}
		}
	}

	&.sejour {

		.breadcrumb * {
			color: $white !important;
		}

		header {
			background-image: url('../images/header-sejour.jpg');
			background-position: center top;
			box-sizing: border-box;

			.headerContent {


				.full-width__wrapper {
					background-color: $white;
					padding: 30px 30px 60px;
					margin-bottom: 100px;
					display: flex;
					flex-flow: row wrap;
					justify-content: space-between;
					margin-top: 80px;

					&.blue {
						border-bottom: 10px solid $blue;

						.sejourInfos {

							&__mention {
								background-color: $blue;
							}

							&__dataBlock {
								border-bottom: 4px solid $blue;

								&.price {
									color: $blue;

									.clear {
										background-color: $blue;
									}
								}
							}

							&__linkedStays {
								border-top:1px solid $blue;
							}

							.buttonContainer {
								.filaireClear {
									border-color: $blue;

									span {
										color: $blue;
									}

									&:hover {
										background-color: $blue;

										span {
											color: $white;
										}
									}
								}
							}
						}

					}

					&.orange {
						border-bottom: 10px solid $orange;

						.sejourInfos {

							&__mention {
								background-color: $orange;
							}

							&__dataBlock {
								border-bottom: 1px solid $orange;

								&.price {
									color: $orange;

									.clear {
										background-color: $orange;
									}
								}
							}

							&__linkedStays {
								border-top:1px solid $orange;
							}
						}

						.buttonContainer {
							.filaireClear {
								border-color: $orange;

								span {
									color: $orange;
								}

								&:hover {
									background-color: $orange;

									span {
										color: $white;
									}
								}
							}
						}

					}


					@include phone-down {
						padding: 20px 20px 40px;
						margin: 40px 0;
					}


					.half-width {
						flex-direction: column;
						align-items: flex-start;
						min-height: 0;
						width: calc(50% - 30px);


						@include tablet-small-down {
							width: 100%;
						}
					}

					.sejourInfos {


						&__title {
							font-size: $large;
							margin-top: 10px;

							@include phone-down {
								padding: 0;
							}
						}

						&__mention {
							display: inline;
							color: $white;
							text-align: left;
							padding: 4px 12px;
							font-weight: 600;
							text-transform: uppercase;
							font-size: $x-small;
							margin-bottom: 0px;
						}

						&__slider {
							width: 100%;
							position: relative;

							ul > li {
								background-position: center !important;
								background-size: cover !important;
							}


						}


						&__linkedStays {
							width:100%;
							margin-top:50px;
							padding-top:25px;

							&__title {

								font-size: $large;
								margin-top: 10px;
							}
						}

						&__dataBlock {
							display: flex;
							flex-flow: row wrap;

							padding: 10px 0;
							width: 100%;


							&.infoLieu {
								background: #f3f3f3;
								border-bottom: none;
								padding: 30px;
								margin-top: 20px;

								div {
									width:100%;
								}

								.container-style {
									padding:0;
								}
							}

							&.price {
								justify-content: space-between;
								align-items: center;

								@include phone-only {
									flex-direction: column;
								}


								.priceValue {
									font-size: $x-large;
									font-weight: 600;
								}

								.buttonContainer {

									@include phone-only {
										margin-top: 10px;
									}

									span {
										color: $white;
									}
								}
							}

							&.prices {


								@include phone-down {
									justify-content: space-between;
								}

								> div {
									margin-right: 30px;

									@include phone-down {
										width: calc(33% - 10px);
										margin-right: 0;
									}

									.priceValue {
										font-size: $normal;
										font-weight: 600;
									}
								}

							}

							&.comprend {
								display: flex;
								flex-flow: row wrap;
								justify-content: space-between;

								.comprend__half {
									width: calc(50% - 30px);

									@include phone-down {
										width: 100%;
										margin-bottom: 30px;
									}

									.container-style {
										padding: 0;
									}

									.content {
										display: block;
									}
								}
							}

							&.infos {
								ul {
									display: flex;
									flex-flow: row wrap;
									justify-content: space-between;
									width: 100%;

									li {
										width: calc(50% - 30px);

										@include tablet-small-down {
											width: 100%;
										}
									}
								}

								.depart {
									font-weight: 400;

									strong {
										font-weight: 600;
									}
								}
							}

							&.description {
								border-bottom: none;

								.container-style {
									padding: 0;
								}
							}
						}


					}

					.downloadButtonContainer {
						margin-top: 20px;

						a {
							svg.hover {
								display: none;
							}

							&:hover {
								svg.normal {
									display: none;
								}

								svg.hover {
									display: inline;
								}
							}
						}
					}


				}
			}
		}


		.sejours__list {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;

			.sejours__list__item {
				margin-top: 40px;
				width: calc(50% - 20px);

				@include tablet-small-down {
					width: 100%;
				}
			}
		}
		.breadcrumb {
			position: absolute;
			left: 0;
			top: -30px;
			margin: 0;
			padding: 0;
			padding-left: 30px;

			@include phone-down {
				display: none;
			}
		}

	}
}

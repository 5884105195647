
$selectric-main-color:      #DDD !default;                                              // Color used in border
$selectric-secondary-color: $blue !default;                                              // Color used in button
$selectric-text-color:      #000000 !default;                                              // Color used in label
$selectric-bg-color:        $white !default;                                           // Background color
$selectric-btn-bg-color:    $darkBlue !default;                                           // Button background color
$selectric-height:          39px !default;                                              // Outer height
$selectric-spacing:         10px !default;                                              // Label left padding
$selectric-border-width:    1px !default;                                               // Outer border width
$selectric-border-radius:   0px !default;                                               // Border radius
$selectric-inner-height:    $selectric-height - ($selectric-border-width * 2) !default; // Inner height
$selectric-font-size:       14px !default;                                              // Font size
$selectric-font-family:       'Open Sans', sans-serif;                                  // Font family

.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: $selectric-border-width solid $selectric-main-color;
  border-radius: $selectric-border-radius;
  background: $selectric-bg-color;
  position: relative;
  overflow: hidden;

  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 $selectric-inner-height 0 $selectric-spacing;
    font-size: $selectric-font-size;
    line-height: $selectric-inner-height;
    color: $selectric-text-color;
    height: $selectric-inner-height;
    user-select: none;
    font-family: $selectric-font-family;
  }

  .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: $selectric-inner-height;
    height: $selectric-inner-height;
    line-height: $selectric-inner-height;
    background-color: $selectric-btn-bg-color;
    color: $selectric-bg-color;
    border: $selectric-border-width solid $selectric-btn-bg-color;
    text-align: center;
    font: 0/0 a;
    *font: 20px/#{$selectric-inner-height} Lucida Sans Unicode, Arial Unicode MS, Arial;

    &:after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top-color: $selectric-bg-color;
      border-bottom: none;
    }
  }
}

.selectric-focus .selectric {
  border-color: darken($selectric-main-color, 20%);
}

.selectric-hover .selectric {
  border-color: darken($selectric-main-color, 10%);

  .button {
    color: darken($selectric-secondary-color, 10%);

    &:after {
      //border-top-color: darken($selectric-secondary-color, 10%);
    }
  }
}

.selectric-open {
  z-index: 9999;

  .selectric {
    border-color: darken($selectric-main-color, 10%);
  }

  .selectric-items {
    display: block;
  }
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;

  select {
    position: absolute;
    left: -100%;
  }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;

    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: $selectric-bg-color;
  border: 1px solid darken($selectric-main-color, 10%);
  z-index: -1;
  box-shadow: 0 0 10px -6px;

  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 100%;
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: $selectric-font-size;
    line-height: 20px;
    min-height: 20px;
  }

  li {
    display: block;
    padding: 10px;
    color: #666;
    cursor: pointer;

    &.selected {
      background: #E0E0E0;
      color: #444;
    }

    &.highlighted {
      background: #0096b6;
      color: #ffffff;
    }

    &:hover {
      background: #0096b6;
      color: #ffffff;
    }
  }

  .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      font-weight: bold;
      padding-left: 10px;
      cursor: default;
      user-select: none;
      background: none;
      color: #444;
    }

    &.disabled li {
      filter: alpha(opacity=100);
      opacity: 1;
    }

    li {
      padding-left: 25px;
    }
  }
}

@import "generic/mediaqueries";
@import "settings/colors";
@import "settings/fonts";

.connexionPage {

	div.error {
		ul {
			li {
				color: #f8897c !important;
			}
		}
	}

	header {
		min-height: 90vh;
		background-image: url('../images/header-connexion.jpg');
		display: block;

		.topHeader {
			padding-top: 40px;
			@include phone-down {
				padding-top: 0;
			}
		}

		.headerContent {
			bottom: 0;

			h2 {
				padding: 0;
			}

			.full-width__wrapper {
				padding: 40px;
				margin: auto;
				margin-bottom: 60px;

				@include phone-down {
					padding: 40px 5%;
				}

				p {
					font-weight: 600;
				}
			}
		}

		.menuContent {
			margin-top: 0;

			@include phone-down {
				display: block;
			}

			.menuLogo {
				@include phone-down {
					position: relative;
					top: 0;
					height: auto;
					margin-bottom: 40px;
					padding-top: 20px;
				}
			}

			.headerBar {
				@include phone-down {
					display: none;
				}
			}


		}
	}

	.connexionBlock {

		a:hover {
			color: $yellow;
		}


		@include phone-down {
			margin: auto 10px;
		}

		.full-width__wrapper {
			background-color: $darkBlue;
			max-width: 400px;
		}

		&__title {
			font-size: $large;
		}

		&__intro {
			font-size: $x-small;
			margin-bottom: 15px;
		}

		.alert {
			margin-bottom: 1em;
		}

		form {
			> div {
				width: 100%;
			}

			.motDePasseContainer {
				width: 100%;
				text-align: right;

				a {
					vertical-align: middle;
					font-size: $x-small;
				}

			}

			.checkboxContainer {
				width: 100%;
			}

			.buttonContainer {
				margin-top: 20px;
				margin-bottom: 10px;
			}
		}
	}

	.inscriptionBlock {

		@include phone-down {
			margin: auto 10px;
		}

		.full-width__wrapper {
			background-color: $darkWhite;
			max-width: 700px;
			margin: auto;
			border-bottom: 10px solid $greenBlue;
		}

		&__title {
			font-size: $large;
			color: $darkBlue;
		}

		&__intro {
			font-size: $x-small;
			margin-bottom: 15px;
		}


	}
}

.fiche {

	width: calc(50% - 10px);
	margin-bottom: 20px;

	@include phone-down {
		width: 100%;
	}

	&s {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}

	&--bordered {
		border: 1px solid $grey;
		border-radius: 5px;
		padding: 15px;
	}

	&__titre {
		font-size: $normal;
		font-weight: 100;
		margin-bottom: 1em;
	}

	&__switcher {
		margin: 15px 0;

		label {
			line-height: 1.6em;
		}

		.pretty {
			white-space: normal !important;
		}

		.pretty.p-switch .state label {
			text-indent: 3.5em;
		}

		.pretty .state label:after, .pretty .state label:before {
			width: calc(1em + 5px);
			height: calc(1em + 5px);
			top: 0px !important;
		}

		.pretty.p-switch.p-fill input ~ .state.p-default:before {
			background-color: $white !important;
			top: 0px !important;

		}

		.pretty.p-switch.p-fill input:checked ~ .state.p-default:before {
			background-color: $blue !important;
		}

		.pretty.p-switch input:checked ~ .state.p-default:before {
			border-color: $blue;
		}
	}

	&__uploader {
		margin-bottom: 15px;
	}


	&__choices {
		display: flex;
		flex-flow: row wrap;
		margin-top: 20px;

		&__item {
			background-color: $blue;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			padding: 7px 15px;
			border-radius: 10px;
			margin-bottom: 10px;
			margin-right: 10px;


			&__label {
				color: $white !important;
				padding-right: 12px;
			}

			&__icon {
				height: 12px;
				width: auto;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__souhaits {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		& > div {
			width: calc(50% - 10px);
		}
	}

	.buttonContainer {
		margin-top: 15px;
		text-align: right;
	}
}

#user_extra_birthdate {
	display: flex;

	.selectric-wrapper {
		width: 33.33%;
	}
}

/*********************************************
  UTILS
*********************************************/
.clear {
	clear: both;
}

.black *, .wysiwyg.black * {
	color: $black;
}

.bold {
	font-weight: 600;
}

.alert {
	padding: 15px;
	background-color: $flashGreen;
	color: $white;
	text-align: center;
	width: 100%;
	margin-bottom: 30px;
	font-family: "Open Sans", sans-serif;

	&.danger {
		background:$red;
	}
}

* {
	box-sizing: border-box;
}

p, li, td, th {
	font-family: $textFont;
	font-size: $small;
	line-height: 1.6em;
	font-weight: 200;
	color: $white;
}

label {
	font-family: $textFont;
	font-size: $small;
	font-weight: 200;
	color: $white;
}

a {
	font-family: $textFont;
	font-size: $small;
	color: $white;
	text-decoration: underline;

	&:hover {
		color: $darkBlue;

	}
}

h1, h2, h3, h4, h5, h6 {
	font-family: $titleFont;
	color: $black;
}

h2 {
	font-family: $textFont;
	color: $white;
	font-size: $large;
	font-weight: 600;
	line-height: 1.4em;

	margin-bottom: 0.4em;
}

h3 {
	font-family: $textFont;
	color: $white;
	font-size: $normal;
	font-weight: 400;
	margin-bottom: 0.4em;
}

input, select, textarea {
	padding: 0;
	border: 1px solid $grey;
}

img {
	width: 100%;
}

svg, main {
	padding: 0;
}

strong {
	font-weight: 400;
}

p + p, p + ul, ul + p {
	margin-top: 1em;
}

.d-none {
	display: none;
}


.checkboxContainer {
	line-height: 1;

	label, div, a {
		line-height: 1;
	}

	label {
		padding-left: .2em;

	}
	.pretty {
		margin-right: 0;
	}

}

.togglerContainer {
	margin-top: 1em;
	margin-bottom: .3em;
	.jtoggler-control {
		display: inline-block;
		height: 20px;
		width: 42px;
	}
	.jtoggler:checked + .jtoggler-control .jtoggler-handle {
		left: 24px;
	}
	.jtoggler-handle {
		height: 18px !important;
		width: 18px !important;
		top: calc(50% - 9px) !important;;
	}

	.jtoggler-label {
		display: inline-block;
		vertical-align: super;
		cursor: pointer;
	}
}


/*********************************************
  STRUCTURE
**********************************************/
.full-width {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	position: relative;

	&__wrapper {
		position: relative;
		width: 100%;
		margin: 40px 80px;

		@include tablet_landscape-down {
			margin: 40px 5%;
		}
	}

	&.right {
		justify-content: flex-end;
	}
}

.half-width {
	width: 50%;
	position: relative;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	min-height: 350px;

	@include tablet-small-down {
		width: 100%;
		min-height: auto;
	}

	&__wrapper {
		position: relative;
		width: 100%;
		margin: 40px 80px;


		&.noTop {
			margin-top: 0;
		}

		@include tablet-landscape-down {
			margin: 40px 10%;
		}
	}
}

.homeAccrocheContainer {
	width: 60%;
	position: relative;

	@include tablet-landscape-down {
		width: 100%;
	}
	&__title {
		width: 100%;

		img, svg {
			float: left;
			display: inline-block;
			margin-right: 15px;
			height: 45px;
			max-width: 45px;
		}

		h2 {
			margin-bottom: 0;

			& + h3 {
				margin-top: 5px;
			}
		}

		h3 {
			font-size: $small;
			font-weight: 600;
			clear: right;
		}
	}

	.buttonContainer {
		margin-top: 20px;
	}


}

.content {
	display: flex;
	flex-flow: row wrap;
}

.sejourInfos__dataBlock.description .content {
	display: block;
}

.noMobile {
	@include phone-down {
		display: none;
	}
}

.noTablet {
	@include tablet-portrait-up {
		display: none;
	}

	@include desktop-up {
		display: block;
	}
}

.noDesktop {
	@include desktop-up {
		display: none;
	}
}

/*********************************************
  BUTTONS
**********************************************/
.buttonContainer {
	vertical-align: middle;

	&.absolute {
		position: absolute;
		right: 0;
		top: 5px;
	}

	&.center {
		text-align: center;
	}


	/* Bounce In */

	.button {
		display: inline-block;
		vertical-align: middle;
		font-family: $titleFont;
		font-size: $small;
		color: $white;
		text-transform: uppercase;
		padding: 14px 30px;
		border-radius: 30px;
		text-decoration: none;
		text-align: center;
		line-height: 1.4em;
		outline: none;
		cursor: pointer;
		border: none;

		//Bounce
		-webkit-transform: perspective(1px) translateZ(0);
		transform: perspective(1px) translateZ(0);
		box-shadow: 0 0 1px rgba(0, 0, 0, 0);
		-webkit-transition-duration: 0.3s;
		transition-duration: 0.3s;
		-webkit-transition-property: transform;
		transition-property: transform;


		&.big {
			padding: 11px 20px;
		}

		&.amazing {
			padding: 14px 30px;
			font-size: $x-small;
		}

		@include desktop-up {
			font-size: $x-small;
			padding: 9px 19px;
		}

		img, svg {
			vertical-align: middle;
			width: 16px;
			height: 16px;
		}

		span {
			vertical-align: middle;
		}

		img + span, svg + span  {
			padding-left: 10px;
		}


		&.clear {
			background-color: transparent;
			color: $black;
		}

		&.orange {
			background-color: $orange;
			color: $black;
		}

		&.darkOrange {
			background-color: $darkOrange;
			color: $white;
		}

		&.lightGreen {
			background-color: $lightGreen
		}

		&.flashGreen {
			background-color: $flashGreen
		}

		&.green {
			background-color: $green
		}

		&.darkGreen {
			background-color: $darkGreen
		}

		&.greenBlue {
			background-color: $greenBlue
		}

		&.lightBlue {
			background-color: $lightBlue
		}

		&.blue {
			background-color: $blue
		}

		&.yellow {
			background-color: #f3c352
		}
		&.yellow2 {
			background-color: $darkOrange
		}

		&.darkBlue {
			background-color: $darkBlue
		}

		&.darkBlue2 {
			background-color: $darkBlue2
		}

		&.darkBlue3 {
			background-color: $darkBlue3
		}

		&.darkBlue4 {
			background-color: $darkBlue4
		}

		&.filaire {
			background-color: transparent;
			border: 1px solid $grey;

			&:hover {
				border-color: $white;
				background-color: $blue;

				span {
					color: $white;
				}
			}
		}

		&.filaireBleu {
			background-color: $darkWhite;
			border: 1px solid $blue;

			span {
				color: $blue;
			}

			&:hover {
				background-color: $blue;

				span {
					color: $white;
				}
			}
		}

		&.filaireClear {
			background-color: $darkWhite;
			border: 1px solid transparent;

			span {
				color: transparent;
			}

			&:hover {
				background-color: transparent;

				span {
					color: $white;
				}
			}
		}

		&:hover, &:focus, &:active {
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
		}
	}
}

//black context
.black {
	.buttonContainer .button {
		&.darkOrange span {
			color: $white;
		}

		&.lightGreen span {
			color: $white;
		}

		&.flashGreen span {
			color: $white;
		}

		&.green span {
			color: $white;
		}

		&.darkGreen span {
			color: $white;
		}

		&.greenBlue span {
			color: $white;
		}

		&.lightBlue span {
			color: $white;
		}

		&.blue span {
			color: $white;
		}

		&.darkBlue span {
			color: $white;
		}

		&.darkBlue2 span {
			color: $white;
		}

		&.darkBlue3 span {
			color: $white;
		}

		&.darkBlue4 span {
			color: $white;
		}

		&.filaire span {
			color: $white;
		}

		&.filaireBleu span {
			color: $white;
		}

		&.filaireClear span {
			color: $white;
		}

		&.darkBlue4 span {
			color: $white;
		}
	}
}

/*********************************************
  SVG
**********************************************/
svg.white {
	fill: $white
}

svg.darkWhite {
	fill: $darkWhite
}

svg.grey {
	fill: $grey
}

svg.darkGrey {
	fill: $darkGrey
}

svg.black {
	fill: $black
}

svg.yellow {
	fill: $yellow
}

svg.orange {
	fill: $orange
}

svg.darkOrange {
	fill: $darkOrange
}

svg.lightGreen {
	fill: $lightGreen
}

svg.green {
	fill: $green
}

svg.flashGreen {
	fill: $flashGreen
}

svg.darkGreen {
	fill: $darkGreen
}

svg.greenBlue {
	fill: $greenBlue
}

svg.lightBlue {
	fill: $lightBlue
}

svg.blue {
	fill: $blue
}

svg.darkBlue {
	fill: $darkBlue
}

svg.darkBlue2 {
	fill: $darkBlue2
}

svg.darkBlue3 {
	fill: $darkBlue3
}

svg.darkBlue4 {
	fill: $darkBlue4
}

/*********************************************
  HEADER
**********************************************/
header {
	position: relative;
	background-image: url('../../images/header-home.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;

	display: flex;
	flex-flow: row wrap;

	min-height: 800px;

	.topHeader {
		width: 100%;
	}

	.menuContent {
		margin: 40px 60px;
		display: flex;
		flex-flow: row wrap;
		position: relative;

		@include phone-down {
			margin: 0;
		}

		@include tablet-portrait-only {
			margin: 40px 30px;
		}
		@include tablet-small-only {
			margin: 40px 30px;
		}


		// LOGO ///////////////////////////////
		.menuLogo {
			height: 126px;

			@include phone-down {
				position: absolute;
				margin: auto;
				width: 100%;
				top: 130px;
			}

			@include tablet-portrait-only {
				height: 110px;
			}
			@include tablet-small-only {
				height: 110px;
			}


			&__container {
				text-align: center;
				line-height: 0;

				&__link {
					display: block;
					background-image: url("../../images/logo.svg");
					background-repeat: no-repeat;
					text-indent: -15000px;
					width: 98px;
					height: 126px;
					color: $black;

					@include phone-down {
						width: 100%;
						background-position: center center;
						//height: 160px;
						//margin-top: 20px;
					}

					@include tablet-portrait-only {
						height: 110px;
					}
					@include tablet-small-only {
						height: 110px;
					}
				}
			}


		}


		// SEARCH / SOCIAL / CONNECTION ///////////////////////////////
		.headerBar {
			width: calc(100% - 108px);
			height: 38px;
			display: flex;
			flex-flow: row wrap;
			justify-content: flex-end;

			@include phone-down {
				width: auto;
				position: absolute;
				right: 0px;
				left: 60px;
				top: 24px;
			}


			.buttonContainer {
				margin-right: 10px;

				.button {
					font-size: $x-small;
					padding: 10px 20px;
				}
			}

			.social {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				width: 120px;

				@include phone-down {
					display: none;
				}


				li {
					display: inline-block;

					a {
						img {
							width: 34px;
							height: 34px;
						}
					}
				}
			}
		}

		// MENU ///////////////////////////////
		.menuBar {
			width: 100%;

			display: flex;
			flex-flow: row nowrap;

			@include phone-down {
				display: none;
			}

			@include tablet-portrait-only {
				margin-top: 20px;
			}
			@include tablet-small-only {
				margin-top: 20px;
			}

			.mainMenu {
				width: 100%;
			}
		}

		.search {
			display: flex;
			flex-flow: row nowrap;
			background-color: $white;

			align-items: center;
			padding: 0px 10px 0px 10px;
			border-radius: 30px;
			margin-right: 10px;
			height: 34px;

			@include phone-down {
				display: none;
			}


			&.opened {

				input {
					display: inline;
				}
			}

			&__icon {
				width: 16px;
				height: 16px;
				cursor: pointer;
			}

			&__input {
				display: none;
				width: 210px;
				font-size: $small;
				border: none;
				padding: 4px 8px;
				outline: none;

			}
		}

	}


}
.headerContent {
	margin: auto;
	width: 100%;

	@include phone-down {
		width: 100%;
		left: 0;
		bottom: 30px;
	}

	h2 {
		font-size: $x-large;
		margin-bottom: 15px;

		@include phone-down {
			padding: 0 30px;
		}
	}
}

.internalPage, .profilePage, .reservationPage {

	header {
		min-height: 400px;

		@include desktop-up {
			min-height: 500px;
		}
		@include big-desktop-up {
			min-height: 600px;
		}

		.menuContent {
			@include phone-down {
				display: block;
			}

			.menuLogo {
				@include phone-down {
					//position: relative;
					margin: auto;
					width: 100%;
					top: 130px;
					margin-top: 140px;
				}
			}

			.headerBar {
				@include phone-down {
					display: none;
				}
			}
		}


	}

	.half-width__wrapper, .full-width__wrapper {
		margin-top: 60px;
		margin-bottom: 60px;
	}
}

.colorsBaseline {
	display: flex;
	flex-flow: row nowrap;

	span {
		flex-basis: calc(100% / 7);
		height: 8px;

		&.orange {
			background-color: $orange
		}

		&.lightGreen {
			background-color: $lightGreen
		}

		&.green {
			background-color: $green
		}

		&.greenBlue {
			background-color: $greenBlue
		}

		&.lightBlue {
			background-color: $lightBlue
		}

		&.blue {
			background-color: $blue
		}

		&.darkBlue {
			background-color: $darkBlue
		}
	}
}

/*********************************************
  MENU
**********************************************/
.mainMenu {
	display: flex;
	justify-content: center;

	ul {
		background-color: rgba(255, 255, 255, .8);
		border-radius: 20px;
		display: flex;
		flex-flow: row wrap;
		align-self: center;
		justify-content: center;

		li {
			a {
				display: inline-block;
				font-family: $titleFont;
				color: $black;
				font-size: $small;
				font-weight: 400;
				text-transform: uppercase;
				padding: 8px 18px;
				text-decoration: none;
				border-radius: 20px;

				@include tablet-portrait-only {
					font-size: $x-small;
					padding: 5px 14px;
				}
				@include tablet-small-only {
					font-size: $x-small;
					padding: 5px 14px;
				}

				&:hover, &:active, &.active, &.selected {
					color: $white;
					background-color: $blue;
				}
			}
		}
	}
}


/*********************************************
  SIDR
**********************************************/

#responsive-menu-button {
	display: none;
	position: absolute;
	top: 25px;
	left: 20px;
	z-index: 100;

	background: url('../../images/burger.svg') no-repeat center top;
	background-size: 100%;
	text-indent: -15000px;
	height: 40px;
	width: 34px;
	padding: 0;
	border-radius: 0;
	font-size: 0;
	vertical-align: top;
	cursor: pointer;

	@include phone-down {
		display: block;
	}
}

/*********************************************
  BREADCRUMB
**********************************************/
.breadcrumb {

	position: relative;

	@include tablet-landscape-up {
	}

	li {
		display: inline-block;

		a {
			font-size: $small;
		}

		&:last-child {
			a {
				text-decoration: none;
			}
		}

	}
}

.half-width {
	.breadcrumb {
		top: 0;
		margin-top: 0.5em;
		padding: 0 80px;
		width: 100%;

		@include tablet-landscape-down {
			padding: 0px 10%;
		}

	}
}

.full-width {
	.breadcrumb {
		top: 0;
		margin-top: 1em;
		padding: 0 80px;
		width: 100%;

		@include tablet-landscape-down {
			padding: 0px 5%;
		}
	}
}

/*********************************************
  WYSIWYG
**********************************************/
.container-style {
	padding: 30px;
}

.wysiwyg {
	padding: 0;

	&.black {

		* {
			color: $black;
		}

		ul li {
			color: $black;
		}
	}

	* {
		color: $white;
	}

	ul {
		list-style: disc;
		padding-left: 20px;
	}

	h3 {
		color: $darkBlue;
		font-weight: 600;
		margin-bottom: 1em;
	}

	img {
		max-width: 100%;
		margin-bottom: 2em;
	}

	a:hover {
		color: $darkBlue3 !important;
	}

	table {
		border-collapse: collapse;
		width: 100%;
		border: 1px solid $grey;

		margin: 1em 0;

		tr {
			border: 1px solid $grey;

			td, th {
				border: 1px solid $grey;
				padding: 0.3em;

			}

			th {
				font-weight: 500;
			}
		}
	}
}

/*********************************************
  SEJOURS
**********************************************/
.sejours {
	&__list {
		* {
			color: $black;
		}

		&__arrow {
			position: absolute;
			z-index: 1;
			top: calc(50% - 30px);
			cursor: pointer;


			img {
				width: auto;
				height: 60px;
			}

			&--prev {
				left: -50px;
			}

			&--next {
				right: -50px;
			}
		}

		&__item {
			background-color: $white;
			border-bottom: 3px solid $flashGreen;
			position: relative;

			display: flex;
			flex-flow: row nowrap;

			@include phone-down {
				flex-direction: column;
			}

			@include tablet-landscape-only {
				flex-direction: column;
			}

			& + div {
				margin-top: 20px;
			}

			&__image {
				width: 40%;
				background-image: url('../../images/home-sejour1.jpg');
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center center;

				position: relative;

				@include phone-down {
					height: 200px;
					width: 100%;
				}

				@include tablet-landscape-only {
					height: 160px;
					width: 100%;
				}

				&__mention {
					display: inline;
					color: $white !important;
					background-color: $flashGreen;
					position: absolute;
					top: 10px;
					padding: 4px 12px;
					margin-left: -10px;
					font-weight: 600;
					text-transform: uppercase;
					font-size: $x-small;
				}
			}

			&__content {
				width: 60%;
				display: flex;
				flex-flow: column nowrap;

				@include phone-down {
					width: 100%;
				}

				@include tablet-landscape-only {
					width: 100%;
				}

				&__half {
					padding: 20px 20px;
					position: relative;
					height: 55%;
					background-color: $darkWhite;
					min-height: 120px;

					h2 {
						font-size: $normal;
					}

					p {
						font-size: $small;
					}

					.buttonContainer {
						position: absolute;
						right: 15px;
						bottom: 15px;

						@include phone-down() {
							position: relative;
							text-align: center;
							right: 0;
							bottom: 0;
							margin-top: 30px;
						}

						span {
							color: $white;
						}
					}

					& + div {
						height: 45%;

						background-color: #effbe0;

						@include phone-down {
							padding-bottom: 40px;
						}

					}
				}
			}
		}
	}
}

.infosUtiles {
	li {
		vertical-align: middle;
		font-size: $small;
		font-weight: 600;
		line-height: 1.8em;

		img, svg {
			display: inline;
			width: 16px;
			vertical-align: middle;
		}

		span {
			vertical-align: middle;
			padding-left: 10px;
		}
	}

}

/*********************************************
  FORM
**********************************************/
form {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;

	> div, div.inputBlock {
		margin-top: 10px;

		&.error {
			ul {
				li {
					color: #DE0C23;
				}
			}
		}


		label {
			display: block;
			font-weight: 400;
			margin-bottom: 3px;
		}

		input[type="text"], input[type="email"], input[type="number"], input[type="phone"], input[type="password"], input[type="date"], textarea {
			width: 100%;
			padding: 10px 12px;
			font-family: $textFont;
			font-weight: normal;
			border: 1px solid $grey;
			outline: none;
		}

		textarea {
			height: 150px;
		}

		.buttonContainer {
			margin-top: 20px;
			text-align: center;

			.button span {
				color: $white;
			}
		}
	}


	.radioContainer {
		&__item {
			display: inline-block;

			label {
				display: inline-block;
				vertical-align: middle;
			}

			input {
				display: inline-block;
				margin-right: 10px;
				vertical-align: middle;
			}

			& + div {
				margin-left: 50px;
			}
		}


	}
}

.checkboxContainer {
	min-height: 30px;

	label {
		display: inline-block !important;
		margin-bottom: 0 !important;
		vertical-align: middle;
		line-height: 1.6em;
	}

	input {
		display: inline-block;
		margin-right: 10px;
		vertical-align: middle;
	}

	.pretty {
		white-space: normal !important;
	}

	.pretty.p-default .state label {
		text-indent: 2em;
	}

	.pretty .state label:before {
		background-color: $white !important;
	}

	.pretty .state label:after, .pretty .state label:before {
		width: calc(1em + 5px);
		height: calc(1em + 5px);
		top: 0px !important;

	}

	.pretty.p-default input ~ .state.p-default:before {
		background-color: $white !important;
		top: 2px !important;
	}

	.pretty.p-default input:checked ~ .state label:after {
		background-color: $blue !important;
	}

}

/*********************************************
  INSCRIPTION FORM
**********************************************/
form#inscriptionForm {
	div.inputBlock {
		width: calc(50% - 10px);

		&.typeUtilisateur, &.institution, &.motivations, &.autre, &.commentAvezVousConnu,
		&.mutualite, &.registreNational, &.isAnimateur, &.username {
			width: 100%;
		}

		&.adresse {
			width: calc(80% - 10px);
		}

		&.numero, &.codePostal {
			width: calc(20% - 10px);
		}

		&.ville, &.pays {
			width: calc(40% - 10px);
		}

		&.isAnimateur {
			margin-top: 20px;
		}


		@include phone-down {
			width: 100% !important;

			&.date, &.sexe {
				width: calc(50% - 10px) !important;
			}

			&.adresse {
				width: calc(70% - 10px) !important;
			}

			&.numero {
				width: calc(30% - 10px) !important;;
			}

			&.codePostal {
				width: calc(40% - 10px) !important;
			}

			&.ville {
				width: calc(60% - 10px) !important;;
			}


		}

	}


	.animateurBlock {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
	}

	.radioContainer {
		&__item {
			width: 100%;
			margin-left: 0;
		}
	}

	.buttonContainer {
		width: 100%;
		margin-top: 20px;
		margin-bottom: 10px;
	}


}

/*********************************************
  ENFANTS
**********************************************/
.enfants {
	display: flex;
	flex-flow: row wrap;

	margin: 0 -10px;

	&__item {
		padding: 20px;
		background-color: $darkWhite;
		width: calc(33% - 20px);
		position: relative;
		margin: 0 10px 20px 10px;

		@include tablet-small-down {
			width: calc(50% - 10px);
		}

		@include phone-down {
			width: 100%;
		}

		&.add {
			background-color:rgba(0,0,0,.5);
			cursor: pointer;
			min-height: 200px;
			display: flex;
			align-items: center;
			justify-content: center;


			svg {
				opacity: .2;
			}

			&:hover {
				svg {
					opacity: 1;
				}
			}

		}

		&__edit {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 18px;
		}

		&__header {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 20px;

			&__icon {
				width: 70px;
				height: 70px;
				background-color: $white;
				border-radius: 50px;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 40%;
				}
			}

			&__txt {
				width: calc(100% - 85px);

				&__nom {
					font-weight: 600;
					font-size: $large;
				}
				&__prenom {
					font-weight: 600;
					font-size: $x-small;
					margin-top: 0;
				}

			}
		}

		&__content {

			&__item {
				background-color: $white;
				border-radius: 20px;
				padding: 3px 12px;
				margin-top: 5px;

				.bold {
					font-weight: 400;
				}
			}
		}
	}
}

/*********************************************
  COMPOSANTS
**********************************************/
.iconBlock {

	a {
		text-decoration: none;
	}

	&__icon {
		vertical-align: middle;
		width: 18px;
	}
	&__txt {
		vertical-align: middle;
		padding-left: 10px;
		font-weight: 400;

		+ span {
			padding-left: 30px;
		}
	}
}

/*********************************************
  ALERTES FORMULAIRES
**********************************************/
.alert {
	position: relative;
	&.alert-danger {
		font-family: $textFont ;
		font-size: $small;
		color: #721c24;
		background-color: #f8d7da;
		border-color: #f5c6cb;
		padding: 15px;

		a {
			color: #721c24;

			&:hover {
				color: #721c24 !important;
			}
		}
	}

	button {
		position: absolute;
		padding: 0;
		top: 0px;
		right: 5px;
		color: #721c24;
		background-color: transparent;
		border: none;
		font-size: $small;
		outline: none;
	}
}

/*********************************************
  MAP POPUP
**********************************************/
.mapPopup {
	&__title {
		&__link {
			font-size: $normal;
			text-decoration: none;
		}
	}

	&__description {
		color: $black;
		margin:5px 0 10px !important;
	}

	svg {
		fill: $black;
	}

	.infosUtiles {}
}

/*********************************************
  FLASH MESSAGES
**********************************************/
.flashMessage {
	position: absolute;
	top: 20px;
	right: 20px;
	max-width: 320px;
	box-shadow: 1px 2px 6px 0px $darkGrey;
}

/*********************************************
  COOKIES MESSAGES
**********************************************/
//#cookie-compliance {
//	display: none;
//}

#cookie-compliance {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 100;
	display: none;
	justify-content: center;
	align-items: center;
	z-index: 10000;

	.black {
		color: $black;
	}

	.modal-dialog {
		* {
			color: $black;
		}

		position: relative;
		max-width: 900px;
		background-color: $white;
		padding: 40px;
		max-height: 700px;
		overflow-y: scroll;

		.modal-body {
			p {
				font-size: $normal;
				margin-bottom: 1em;
			}



			th {
				font-weight: bold;
				background-color: $darkWhite;
			}

			th, td{
				border: 1px solid $grey;
				padding: 10px;
			}

			.text-center {
				text-align: center;
				width: 100%;
			}

			button {
				display: inline-block;
				vertical-align: middle;
				font-family: $titleFont;
				font-size: $small;
				color: $white;
				background-color: $blue;
				text-transform: uppercase;
				padding: 14px 30px;
				border-radius: 30px;
				text-decoration: none;
				text-align: center;
				line-height: 1.4em;
				outline: none;
				cursor: pointer;
				border: none;
			}
		}

	}

	@include tablet-small-down {
		thead {
			display: none;
		}

		th, td {
			border: none !important;
			display: inline-block;
		}

		td:nth-child(1) {
			width: 100%;
			font-weight: bold;
		}

		td:nth-child(2) {
			width: 100%;
		}

		td:nth-child(3), td:nth-child(4), td:nth-child(5) {
			width: calc(100% / 3 - 5px);
		}


			tr {
			display: block;
			border: 1px solid $grey;
		}
	}
}

.js .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    font-size: $x-small;
    width: 100%;
    /* 20px */
    font-weight: 700;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 7px 12px;
    /* 10px 20px */
}

.no-js .inputfile + label {
    display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label * {
    /* pointer-events: none; */
    /* in case of FastClick lib use */
}

.inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    /* 4px */
    margin-right: 0.25em;
    /* 4px */
}

/* style 6 */

.inputfile-6 + label {
    color: $darkBlue;
}

.inputfile-6 + label {
    border: 1px solid $grey;
    background-color: $darkBlue;
    padding: 0;
}

.inputfile-6:focus + label,
.inputfile-6.has-focus + label,
.inputfile-6 + label:hover {
    border-color: $blue;
    background-color: $blue;
}

.inputfile-6 + label span,
.inputfile-6 + label strong {
    /* 10px 20px */
}

.inputfile-6 + label span {
    width: calc(100% - 84px);
    min-height: 2em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
    padding: 9px;
    font-size: 14px;
    font-weight: normal;
    background-color: $white;
    height: 32px;
}

.inputfile-6 + label strong {
    height: 100%;
    color: $white;
    background-color: $darkBlue;
    display: inline-block;
    padding: 10px;
    width: 80px;
    text-align: center;
}

.inputfile-6:focus + label strong,
.inputfile-6.has-focus + label strong,
.inputfile-6 + label:hover strong {
    background-color: $blue;
}

@media screen and (max-width: 50em) {
	.inputfile-6 + label strong {
		//display: block;
	}
}

/*********************************************
  BREAKPOINTS
**********************************************/

//PHONE
@mixin phone-small-up {
	@media (min-width: 320px) { @content ; }
}
@mixin phone-small-only {
	@media (min-width: 320px) and (max-width: 424px) { @content; }
}
@mixin phone-small-down {
	@media (max-width: 424px) { @content; }
}

//PHONE
@mixin phone-up {
	@media (min-width: 425px) { @content ; }
}
@mixin phone-only {
	@media (min-width: 425px) and (max-width: 599px) { @content; }
}
@mixin phone-down {
	@media (max-width: 599px) { @content; }
}

//TABLET -- portrait
@mixin tablet-portrait-up {
	@media (min-width: 600px) { @content; }
}
@mixin tablet-portrait-only {
	@media (min-width: 600px) and (max-width: 749px) { @content; }
}
@mixin tablet-portrait-down {
	@media (max-width: 749px) { @content; }
}

//TABLET -- small
@mixin tablet-small-up {
	@media (min-width: 750px) { @content; }
}
@mixin tablet-small-only {
	@media (min-width: 750px) and (max-width: 899px) { @content; }
}
@mixin tablet-small-down {
	@media (max-width: 899px) { @content; }
}

//TABLET -- landscape
@mixin tablet-landscape-up {
	@media (min-width: 900px) { @content; }
}
@mixin tablet-landscape-only {
	@media (min-width: 900px) and (max-width: 1049px) { @content; }
}
@mixin tablet-landscape-down {
	@media (max-width: 1049px) { @content; }
}

//TABLET -- big
@mixin tablet-big-up {
	@media (min-width: 1050px) { @content; }
}
@mixin tablet-big-only {
	@media (min-width: 1050px) and (max-width: 1199px) { @content; }
}
@mixin tablet-big-down {
	@media (max-width: 1199px) { @content; }
}

//DESKTOP
@mixin desktop-up {
	@media (min-width: 1200px) { @content; }
}
@mixin desktop-only {
	@media (min-width: 1200px) and (max-width: 1449px) { @content; }
}
@mixin desktop-down {
	@media (max-width: 1449px) { @content; }
}

//DESKTOP -- big
@mixin desktop-big-up {
	@media (min-width: 1450px) { @content; }
}
@mixin desktop-big-only {
	@media (min-width: 1450px) and (max-width: 1799px) { @content; }
}
@mixin desktop-big-down {
	@media (max-width: 1799px) { @content; }
}

//4k
@mixin big-desktop-up {
	@media (min-width: 1800px) { @content; }
}
/* ELEA
  *
  * /sass/settings/_fonts.scss - Global fonts variables
  * by Sosa!
  * created at 04/2018
  */

// size golden proportions scale
// 0.382em
$xx-small     : 0.60rem;
$x-small     : 0.75rem;
$small       : 0.887rem;
$normal      : 1rem;
$large       : 1.272rem;
$x-large     : 1.618rem;
$xx-large    : 2.058rem;
$xxx-large   : 2.618rem;
$xxxx-large  : 3.756em;
$xxxxx-large : 4.236em;
$xxxxxx-large : 8.236em;
// 2.953em
// 3.33em
// 3.756em
// 4.236em
// 4.778em

@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');

// Variables
$titleFont:  'Montserrat', sans-serif;
$textFont:  'Open Sans', sans-serif;


/*********************************************
  FOOTER
**********************************************/
footer {
	display: flex;
	flex-flow: row wrap;

	.full-width {
		padding: 15px 30px;
	}

	.newsletter {
		background-color: $darkBlue2;

		@include tablet-small-down {
			 order: 2;
			 background-color: $darkBlue4;
		 }

		.homeAccrocheContainer {
			@include tablet-small-down {
				width: 100%;
			}
		}



		form {
			margin-top: 40px;
			> div {
				display: flex;
				flex-flow: row nowrap;
				align-items: center;
				width: 100%;

				@include tablet-portrait-only {
					justify-content: center;
				}
				@include tablet_small-only {
					justify-content: center;
				}

				& + div {
					margin-top: 6px;
				}

				label {
					display: inline-block;
					font-weight: 600;
					width: 60px;
					color: $white;

					@include phone-down {
						font-weight: 300;
					}
				}

				input[type=text] {
					max-width: 350px;
					width: calc(100% - 60px);
					padding: 12px 20px;
					border: none;
				}

				.buttonContainer {
					margin-top: 20px;
					margin-left: 60px;

					@include phone-down {
						margin: 20px auto 0 auto;
					}
				}
			}

			.checkboxContainer {
				padding-left: 63px;

				@include tablet-portrait-only {
					justify-content: center;
					text-align: center;
				}
				@include tablet_small-only {
					justify-content: center;
					text-align: center;
				}

				&__item {
					width: 100%;
					color: $white;

					input {
						padding-left: 70px;
						vertical-align: middle;
						margin-right: 0;
					}

					label {
						width: calc(100% - 19px);
						max-width: 350px;
						padding-left: 6px;
						vertical-align: middle;
					}
				}

			}

		}
	}

	.contact {
		background-color: $darkBlue4;
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;


		@include tablet-small-down {
			order: 1;
			background-color: $darkBlue2;
		}

		&__menu {
			background-color: $darkBlue3;
			width: 100%;

			@include tablet-small-down {
				order: 2;
			}

			ul {
				padding: 30px;
				display: flex;
				flex-flow: row wrap;
				align-self: center;

				@include tablet-small-down {
					justify-content: center;
				}

				li {
					border-radius: 20px;

					@include phone-down {
						background-color: $blue;
						margin-bottom: 20px;
						margin-right: 10px;
					}

					a {

						display: inline-block;
						font-family: $titleFont;
						color: white;
						font-size: $x-small;
						text-transform: uppercase;
						padding: 8px 24px;
						text-decoration: none;

						@include phone-down {
							padding: 12px 12px;
						}
					}

					&:hover {
						background-color: $green;
					}
				}
			}
		}

		&__infos {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
			width: 100%;

			@include phone-down {
				justify-content: center;
			}


			&__logo {
				width: 105px;

				@include phone-down {
					order: 2;

					display: none;

					&.mobile {
						width: 100%;
						background-color: $darkBlue3;
						text-align: center;
						display: block;
						padding-bottom: 20px;

						img {
							width: 20%;
						}
					}
				}

				@include tablet-landscape-only {
					width: 85px;
				}



			}

			&__contact {
				width: calc(100% - 155px);

				@include phone-down {
					order: 1;
					width: 100%;
					margin-bottom: 20px;
					text-align: center;
				}

				@include tablet-portrait-only {
					text-align: center;
				}

				@include tablet-landscape-only {
					width: calc(100% - 115px);

				}

				h3 {
					font-size: $x-large;
				}

				p {
					font-size: $normal;


					svg {
						display: inline;
						vertical-align: middle;
					}

					span {
						vertical-align: middle;
						padding-left: 10px;
					}


					a {
						color: white;
						font-size: $normal;
						text-decoration: none;

						&:hover {
							text-decoration: underline;
						}
					}

					+ p {
						margin-top: 0.4em;
					}

					&#normalPhone {
						@include phone-down {
							display: none;
						}
					}

					&#mobilePhone {
						display: none;
						margin-top: 40px;
						text-align: center;

						@include phone-down {
							display: block;
						}
					}
				}
			}

		}

		&__social {
			background-color: $darkBlue3;
			width: 100%;
			padding: 30px;
			text-align: right;

			display: flex;
			flex-flow: row wrap;
			justify-content: flex-end;
			align-items: center;

			@include tablet-small-down {
				background-color: $darkBlue3;
				justify-content: center;
				padding-top: 0;
				order: 3;
			}

			li {
				vertical-align: middle;

				a {
					img {
						width: 34px;
						height: 34px;
					}
				}

				&:first-child {
					color: $white;
					font-family: $textFont;
					font-size: $normal;
					font-style: italic;

					@include phone-down {
						display: none;
					}
				}

				+ li {
					margin-left: 10px;
				}
			}

		}
	}

	.partenaires {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		order: 3;

		@include desktop-up {
			order: 0;
			flex-flow: row nowrap;
			justify-content: flex-start;
		}

		&__logo {
			text-align: center;
			margin-right: 0;
			width: calc(33% - 10px);

			@include desktop-up {
				order: 0;
				margin-right: 50px;
				width: auto;
			}

			&__image {
				max-width: 100%;
				max-height: 55px;
				width: auto;
			}

		}
	}

	.baseline {
		background-color: $grey;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;

		order: 3;

		@include desktop-up {
			order: 0;
			flex-flow: row nowrap;
		}

		&__mentions {
			display: flex;
			flex-flow: row wrap;
			width: 100%;
			order: 2;
			justify-content: space-around;
			line-height: 150%;

			@include desktop-up {
				order: 0;
				justify-content: flex-start;

			}

			li {
				display: inline-block;
				padding-right: 20px;
				color: $darkGrey;
				font-weight: 100;
				font-size: $x-small;
				vertical-align: middle;

				a{
					font-weight: 100;
					color: $darkGrey;
					font-size: $x-small;
					vertical-align: middle;
				}
			}
		}

		&__creditCards {
			display: flex;
			flex-flow: row wrap;
			order: 1;
			margin-bottom: 20px;
			width: 100%;
			justify-content: center;
			margin-right: 50px;

			@include desktop-up {
				flex-flow: row nowrap;
				order: 0;
				margin-bottom: auto;
				width: auto;
			}

			&__image {

				@include phone-down {
					left: 0;
					width: calc(33% - 10px);
				}

				margin-left: 10px;

				img {
					width: auto;
				}
			}
		}

		.oannaLink {
			order: 3;
			position: relative;
			width: 100%;
			margin-top: 20px;


			@include desktop-up {
				position: absolute;
				width: 25px;
				right: 20px;
				margin-top: 0px;

			}

			img {
				height: 20px;
			}
		}

	}

}
